import {
  HomeFeaturedProducts,
  HomeHero,
  HomeOurBusiness,
  HomeTags,
  HomeSubscribe,
  HomeOurBrands,
  LanguageContext,
} from '@/components';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React , {useState, useEffect,useContext}from 'react';
import GetRequest from 'src/logic/utils/create-request/Helpers/GetRequest';

const useStyles = makeStyles(() => ({
  root: {},
}));
interface Props {}

const Page: React.FC<Props> = () => {
  const cls = useStyles();
  
  const [content,setContent] = useState<any>({});
  const language = useContext(LanguageContext);
  
  useEffect(() => {
    const request = async ()=>{
      let res = await GetRequest('home');
      if(res && res.success){
        let cont = res.data.find((d: any) => d.language_id === language.id);
        if(cont === undefined){
          cont = res.data.find((d: any) => d.language_id === 2);
        }
        setContent(cont);
      }
    }
    request();
  }, [language.id])
  return (
    <div className={cls.root}>
      <HomeHero content={content} />
      <Box py={2} />
      <HomeTags />
      <Box py={6} />
      <HomeOurBusiness />
      <Box py={6} />
      <HomeFeaturedProducts />
      <Box py={6} />
      <HomeOurBrands />
      <Box py={6} />
      <HomeSubscribe />
      <Box py={4} />
    </div>
  );
};

export default Page;
